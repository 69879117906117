export function numberWithSpaces(number, decimals=0) {
    if (Math.abs(number) < 1.) {
        return number.toFixed(2)
    }
    // var parts = Math.round(x).toString().split(".");
    var parts = number.toFixed(decimals).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function average (arr) {
    const avg = arr.reduce( ( p, c ) => p + c, 0 ) / arr.length; 
    return Math.round( avg );
}

export function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export const provinces = {
    'Alberta': 'AB',
    'Colombie Britannique': 'BC',
    'Ile du Prince Edward': 'PE',
    'Manitoba': 'MB',
    'Nouveau Brunswick': 'NB',
    'Nouvelle Ecosse': 'NS',
    'Ontario': 'ON',
    'Québec': 'QC',
    'Saskatchewan': 'SK',
    '-': '-'
}

export const coleursCompteurs = {
    'Électricité #1': '#58595B',
    'Électricité #2': '#8C8D90',
    'Électricité #3': '#BBBCBD',
    'Gaz #1': '#0060AE',
    'Gaz #2': '#0089FA',
    'Gaz #3': '#69BBFF',
    'Mazout #2 #1': '#996633',
    'Mazout #2 #2': '#C4884C',
    'Mazout #6': '#c7c7c7',
    'Vapeur #1': '#dee78d',
    'Propane #1': '#004782',
    'Biomasse #1': '#C3D332',
    'Intensité': '#FAA938', 
    'Total': '#262626'
}

export const coleursSources = {
    'Électricité': coleursCompteurs['Électricité #1'],
    'Gaz': coleursCompteurs['Gaz #1'],
    'Mazout #2': coleursCompteurs['Mazout #2 #1'],
    'Mazout #6': coleursCompteurs['Mazout #6'],
    'Vapeur': coleursCompteurs['Vapeur #1'],
    'Propane': coleursCompteurs['Propane #1'],
    'Biomasse': coleursCompteurs['Biomasse #1'],
}

export function makeWeatherNormTrace(dataEntries, availableWeatherNormData) {
    var trace_reel_template = {
        "type": "scatter",
        "x": ["2007-01-01","2007-02-01"],
        "y": [1,2],
        'name': '',
        'line': {'shape': 'spline', 'smoothing': 0.8, 'dash':'solid'},
        'marker': {},
        'showlegend': true,
        'visible': true,
    }
    var trace_ci_upper_template = {
        "type": "scatter",
        "x": ["2007-01-01","2007-02-01"],
        "y": [2,3],
        'mode': 'lines',
        'name': 'Zone de consommation prévue',
        'line': {width:0, shape: 'spline', smoothing: 0.8, color:'#7e7f82'},
        'showlegend': false,
        'visible': true,
    }
    var trace_ci_lower_template = {
        "type": "scatter",
        "x": ["2007-01-01","2007-02-01"],
        "y": [0.5,1.5],
        'mode': 'lines',
        'name': 'Zone de consommation prévue',
        'line': {width:0, shape: 'spline', smoothing: 0.8, color:'#7e7f82'},
        'showlegend': true,
        'fillcolor':'rgba(68, 68, 68, 0.1)',
        'fill':'tonexty',
        'visible': true,
    }

    var traces = [];
    if (availableWeatherNormData) {
        dataEntries.forEach(([key, value], index) => {
            // copy and edit of trace templates
            // reel
            var trace_reel = { 
                ...trace_reel_template,
                name: key,
                y: value.consommation,
                visible: index === 0,
                line:{
                    ...trace_reel_template.line,
                    color: coleursCompteurs[key]
                }    
            }
            // ci_upper
            var trace_ci_upper = { 
                ...trace_ci_upper_template,
                y: value.mean_ci_upper,
                visible: index === 0,  
            }
            // ci_lower
            var trace_ci_lower = { 
                ...trace_ci_lower_template,
                y: value.mean_ci_lower,
                visible: index === 0,  
            }
            // multiple assignment x axis
            trace_reel.x = trace_ci_upper.x = trace_ci_lower.x = value.date
            // append to array
            traces.push(trace_reel)
            traces.push(trace_ci_upper)
            traces.push(trace_ci_lower)
        })
    } else {
        // copy and edit of trace templates
        // reel
        var trace_reel = { 
            ...trace_reel_template,
            name: "Non-disponible",
            y: [null],
            visible: false,
            line:{
                ...trace_reel_template.line,
                color: coleursCompteurs["Non-disponible"]
            }    
        }
        // ci_upper
        var trace_ci_upper = { 
            ...trace_ci_upper_template,
            y: [null],
            visible: false,  
        }
        // ci_lower
        var trace_ci_lower = { 
            ...trace_ci_lower_template,
            y: [null],
            visible: false,  
        }
        // multiple assignment x axis
        trace_reel.x = trace_ci_upper.x = trace_ci_lower.x = [null]
        // append to array
        traces.push(trace_reel)
        traces.push(trace_ci_upper)
        traces.push(trace_ci_lower)
        
    }

    return traces;
}

export function setUpdateMenus(dataEntries) {
    var size = Object.keys(dataEntries).length

    var button_template = {
        'method': 'restyle',
        'args': ['visible', []],
        'label': ''
    }

    var buttons = []

    dataEntries.forEach(([key, value], index) => {
        var startIndexTrue = index * 3
        var arrayVisible = Array(size*3).fill(false)
        arrayVisible.splice(startIndexTrue, 3, true,true,true)

        var button = { ...button_template }
        button.args = ['visible', arrayVisible]
        button.label = key
        buttons.push(button)
    })
    return buttons
}

export function filterObject(object, desiredKeys) { 
    Object.keys(object)
        .forEach(key_1 => Object.keys(object[key_1])
        .filter(key_2 => !desiredKeys.includes(key_2))
        .forEach(key_2 => delete object[key_1][key_2]))
}

export function makeTraces(dataEntries, tracesKeys) {
    var trace_intensite_template = {
        "type": "line",
        "x": ["2007-01-01","2007-02-01"],
        "y": [1,2],
        'name': '',
        'line': {'shape': 'spline', 'smoothing': 0.7, 'dash':'solid', color:coleursCompteurs['Intensité']},
        'yaxis': 'y2',
        'hovertemplate': `%{y:.2f}<extra>Intensité [TEST]</extra>`
    }
    var trace_bar_template = {
        "type": "bar",
        "x": ["2007-01-01","2007-02-01"],
        "y": [2,3],
        "perc": [1,1],
        'mode': 'lines',
        'name': '',
        'marker': {color:'#7e7f82'},
        'showlegend': true,
        'visible': true,
    }
    var trace_total_bar_template = {
        "type": "scatter",
        "x": ["2007-01-01","2007-02-01"],
        "y": [2,3],
        "perc": [1,1],
        'mode': 'markers',
        'name': '',
        'marker': {color:'#7e7f82'},
        'opacity': 0.,
        'showlegend': false,
        'visible': false,
    }

    var traces = []

    dataEntries.forEach(([key, value], index) => {
        // copy and edit of trace templates
        // console.log(value)
        if (key == 'Total') {
            if (typeof value[tracesKeys['total']['scatterTraceKey']] !== 'undefined') {
                // Intensite
                var trace_intensite_total = { 
                    ...trace_intensite_template,
                    x: value.date,
                    y: value[tracesKeys['total']['scatterTraceKey']],
                    name: tracesKeys['total']['name']
                }
            }

            // Total
            var trace_bar_total = { 
                ...trace_total_bar_template,
                x: value.date,
                y: value[tracesKeys['total']['barTraceKey']],
            }

            traces.push(trace_intensite_total,trace_bar_total)
        } else {
            // Intensite
            var trace_intensite_compteur = { 
                ...trace_intensite_template,
                x: value.date,
                y: value[tracesKeys['compteurs']['scatterTraceKey']],
                visible: false
            }
            // Bar
            var trace_bar_compteur = { 
                ...trace_bar_template,
                x: value.date,
                y: value[tracesKeys['compteurs']['barTraceKey']],
                name: key
            }
            traces.push(trace_intensite_compteur,trace_bar_compteur)
        }

        // multiple assignment x axis
        // trace_intensite_total.x = trace_bar_total.x = trace_intensite_compteur.x = trace_bar_compteur.x = value.date
        // append to array
        
    })

    return traces;
}

export const traceKeysConsommation = {
    'compteurs': {
        'barTraceKey':'consommation',
        'scatterTraceKey':'intensite_energetique'
    },
    'total': {
        'barTraceKey':'consommation',
        'scatterTraceKey':'intensite_energetique',
        'name':'Intensité énergétique'
    }
}

export const traceKeysCouts = {
    'compteurs': {
        'barTraceKey':'cout_normalise',
        'scatterTraceKey':'intensite_monetaire'
    },
    'total': {
        'barTraceKey':'cout_normalise',
        'scatterTraceKey':'intensite_monetaire',
        'name':'Intensité monétaire'
    }
}

export const traceKeysEmissions = {
    'compteurs': {
        'barTraceKey':'emissions_GES',
        'scatterTraceKey':'intensite_emissions'
    },
    'total': {
        'barTraceKey':'emissions_GES',
        'scatterTraceKey':'intensite_emissions',
        'name':''
    }
}