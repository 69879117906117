import { combineReducers } from 'redux';

import frontend from './frontend';
import auth from './auth';
import info_batiment from './info_batiment';
import messages from './messages';

export default combineReducers({
    frontend,
    info_batiment,
    auth,
    messages,
});