// FRONTEND
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const CHANGE_PRE_LAYOUT = 'CHANGE_PRE_LAYOUT';
export const GET_SELECTED_BATIMENT = 'GET_SELECTED_BATIMENT';

// API
export const GET_BATIMENTS = 'GET_BATIMENTS';
export const GET_BATIMENTS_FAIL = 'GET_BATIMENTS_FAIL';
export const GET_INFO_BATIMENT = 'GET_INFO_BATIMENT';
export const GET_INFO_BATIMENT_FAIL = 'GET_INFO_BATIMENT_FAIL';
export const GET_STATS_CONSOMMATION = 'GET_STATS_CONSOMMATION';
export const GET_MIX_ENERGIE = 'GET_MIX_ENERGIE';
export const GET_DATA_12MOIS = 'GET_DATA_12MOIS';
export const GET_DATA_12MOIS_FAIL = 'GET_DATA_12MOIS_FAIL';
export const GET_STATS_PERIODE = 'GET_STATS_PERIODE';
export const GET_STATS_PERIODE_LOADING = 'GET_STATS_PERIODE_LOADING';
export const GET_STATS_PERIODE_FAIL = 'GET_STATS_PERIODE_FAIL';
export const GET_LOGO_COMPANY = 'GET_LOGO_COMPANY';
export const INITIAL_STATE = 'INITIAL_STATE';
export const GET_WEATHER_NORM_DATA = 'GET_WEATHER_NORM_DATA'
export const GET_WEATHER_NORM_DATA_FAIL = 'GET_WEATHER_NORM_DATA_FAIL'
export const GET_WEATHER_NORM_DATA_LOADING = 'GET_WEATHER_NORM_DATA_LOADING'
export const DATA_LOADING = 'DATA_LOADING'
    // CHART DATA
export const AWAITING_CHART_DATA = 'AWAITING_CHART_DATA';
export const SUCCESS_CHART_DATA = 'SUCCESS_CHART_DATA';
export const REJECTED_CHART_DATA = 'REJECTED_CHART_DATA';
export const INCREMENT = 'INCREMENT';
export const CHANGE_BARMODE = 'CHANGE_BARMODE';
export const CHANGE_BARNORM = 'CHANGE_BARNORM';
export const FILTER_DATA = 'FILTER_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const RESET_DATA = 'RESET_DATA';
export const UPDATE_START = 'UPDATE_START';
export const UPDATE_END = 'UPDATE_END';
export const CHANGE_KWH_GJ = 'CHANGE_KWH_GJ';

export const GET_CHART_DATA = 'GET_CHART_DATA';
export const GET_CHART_DATA_FAIL = 'GET_CHART_DATA_FAIL';
export const GET_CHART_DATA_LOADING = 'GET_CHART_DATA_LOADING';
    // STACKED CHART DATA
export const AWAITING_STACKED_CHART_DATA = 'AWAITING_STACKED_CHART_DATA';
export const SUCCESS_STACKED_CHART_DATA = 'SUCCESS_STACKED_CHART_DATA';
export const REJECTED_STACKED_CHART_DATA = 'REJECTED_STACKED_CHART_DATA';

// Messages
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';
// Authentication
export const USER_LOADED = 'USER_LOADED'; 
export const USER_LOADING = 'USER_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL'; 
export const LOGIN_SHOW_OTP = 'LOGIN_SHOW_OTP';
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS'; 
export const LOGIN_OTP_FAIL = 'LOGIN_OTP_FAIL'; 
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'; 
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'; 
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const RETRY_LOGIN = 'RETRY_LOGIN';
export const INITIAL_STATE_AUTH = 'INITIAL_STATE_AUTH';
    // Change password
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CLOSE_ERROR = 'CLOSE_ERROR';
export const CLOSE_SUCCESS = 'CLOSE_SUCCESS';
export const CLOSE_MODAL_CHANGE_PWD = 'CLOSE_MODAL_CHANGE_PWD';
    // Forgot password
export const CHECK_USERNAME = 'CHECK_USERNAME';
export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAIL = 'CHECK_USERNAME_FAIL';
export const CHANGE_FORGOT_PASSWORD = 'CHANGE_FORGOT_PASSWORD';
export const CHANGE_FORGOT_PASSWORD_SUCCESS = 'CHANGE_FORGOT_PASSWORD_SUCCESS';
export const CHANGE_FORGOT_PASSWORD_FAIL = 'CHANGE_FORGOT_PASSWORD_FAIL';