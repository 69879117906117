import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import config from '../../../../config';
import navigation from '../../../../menu-items';
import DEMO from "../../../../store/constant";
import Aux from "../../../../hoc/_Aux";
class Breadcrumb extends Component {
    state = {
        main: [],
        item: [],
        extra: []
    };

    componentDidMount() {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item, index);
            }
            return false;
        });
    };

    componentWillReceiveProps = () => {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item);
            }
            return false;
        });
    };

    getCollapse = (item) => {
        const location = document.location.pathname;
        if (item.children) {
            (item.children).filter( collapse => {
                if (collapse.type && collapse.type === 'collapse') {
                    this.getCollapse(collapse,);
                } else if (collapse.type && collapse.type === 'item') {
                    const configLocation = config.basename + collapse.url;
                    if (location === configLocation || location.includes(configLocation)) {
                        let extra = false;
                        if(location !== configLocation) {
                            extra = ((location.substring(configLocation.length, location.length)).split('/')).filter(function (el) {
                                return el !== "";
                            });
                        }
                        this.setState({item: collapse, main: item, extra: extra});
                    }
                }
                return false;
            });
        }
    };

    render() {

        let main, item, extra;
        let breadcrumb = '';
        let title = 'Welcome';
        if (this.state.main && this.state.main.type === 'collapse') {
            main = (
                <li className="breadcrumb-item">
                    <a href={DEMO.BLANK_LINK}>{this.state.main.title}</a>
                </li>
            );
        }
        if (this.state.item && this.state.item.type === 'item') {
            title = this.state.item.title;
            const titleBreadcrumb = this.state.item.titleBreadcrumb;
            item = (
                <li className="breadcrumb-item">
                    <a href={DEMO.BLANK_LINK}>{title}</a>
                </li>
            );

            if (this.state.extra) {
                extra = (this.state.extra).map((n, index) => {
                    return (
                        <li className="breadcrumb-item" key={index}>
                            <a href={DEMO.BLANK_LINK}>{n.charAt(0).toUpperCase() + n.slice(1)}</a>
                        </li>
                    );
                })
            }

            if(this.state.item.breadcrumbs !== false) {
                breadcrumb = (
                    <div className="page-header p-l-30">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="page-header-title">
                                        <p className="m-b-10" style={{fontSize:'35px', color:'black'}}>{titleBreadcrumb.toUpperCase()}</p>
                                    </div>
                                    {/* <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"><i className="feather icon-home"/></Link>
                                        </li>
                                        {main}
                                        {item}
                                        {extra}
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

        }

        // document.title = title + ' | Datta Able Premium React + Redux Admin Template';

        return (
            <Aux>
                {breadcrumb}
            </Aux>
        );
    }
}

export default Breadcrumb;