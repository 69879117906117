import axios from 'axios';

import API_URL from '../api_constants'
import * as actionTypes from '../actions/types';
import { tokenConfig, credentialConfig } from './auth'

// GET CHART DATA
export const getChartData = (value) => (dispatch, getState) => {
    
    // Data loading
    dispatch({ type: actionTypes.GET_CHART_DATA_LOADING });
    
    axios
    .get(`${API_URL.GET_CHART_DATA}?batiment=${value}`, credentialConfig(getState))
    .then((res) => {
        dispatch({
            type: actionTypes.GET_CHART_DATA,
            payload: res.data
        });
    })
    .catch((error) => {
        dispatch({
            type: actionTypes.GET_CHART_DATA_FAIL,
        })
    });
};

// GET BATIMENTS
export const getBatiments = () => (dispatch, getState) => {
  axios
    .get(API_URL.GET_BATIMENTS, credentialConfig(getState))
    .then((res) => {
      dispatch({
        type: actionTypes.GET_BATIMENTS,
        payload: res.data.batiments,
      });
    })
    .catch((error) => {
        dispatch({
            type: actionTypes.GET_BATIMENTS_FAIL,
        })
    });
};

// GET SELECTED BATIMENT
export const getSelectedBatiment = (value) => (dispatch, getState) => {
    
    // Data loading
    dispatch({ type: actionTypes.DATA_LOADING });
    
    axios
    .get(`${API_URL.GET_INFO_BATIMENT}?batiment=${value}`, credentialConfig(getState))
    .then((res) => {
        dispatch({
            type: actionTypes.GET_INFO_BATIMENT,
            // payload: {response: res.data.chart_data_brute, value: value}
            payload: res.data
        });
    })
    .catch((error) => {
        dispatch({
            type: actionTypes.GET_INFO_BATIMENT_FAIL,
        })
    });
    axios
    .get(`${API_URL.GET_DATA_12MOIS}?batiment=${value}`, credentialConfig(getState))
    .then((res) => {
        dispatch({
            type: actionTypes.GET_DATA_12MOIS,
            payload: res.data
        });
    })
    .catch((error) => {
        dispatch({
            type: actionTypes.GET_DATA_12MOIS_FAIL,
        })
    });
  };

// GET STATS PERIODE
export const getStatsPeriode = (value, debut, fin) => (dispatch, getState) => {
    
    // Data loading
    dispatch({ type: actionTypes.GET_STATS_PERIODE_LOADING });
    
    axios
    .get(`${API_URL.GET_STATS_PERIODE}?batiment=${value}&debut=${debut}&fin=${fin}`, credentialConfig(getState))
    .then((res) => {
        dispatch({
            type: actionTypes.GET_STATS_PERIODE,
            payload: res.data
        });
    })
    .catch((error) => {
        dispatch({
            type: actionTypes.GET_STATS_PERIODE_FAIL,
        })
    });
};

// GET LOGO COMPANY
export const getLogoCompany = () => (dispatch, getState) => {
    axios
        .get(API_URL.GET_LOGO_COMPANY, credentialConfig(getState))
        .then((res) => {
            dispatch({
                type: actionTypes.GET_LOGO_COMPANY,
                payload: res.data.logo_company,
            });
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            // console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            // console.log('Error', error.message);
        }
        // console.log(error.config);
    });
  };

// DEPRECATED
// GET INFO BATIMENT
export const getInfoBatiment = ( value ) => async (dispatch, getState) => {
    try {
        dispatch({
            type: actionTypes.AWAITING_CHART_DATA, 
        })

        const response = await axios.get(`${API_URL.GET_INFO_BATIMENT}?batiment=${value}`, credentialConfig(getState))

        dispatch({
            type: actionTypes.SUCCESS_CHART_DATA,
            payload: {
                infoBatiment: response.data.info_batiment,
                compteurs: response.data.compteurs,
            }
        })
    } catch (e) {
        dispatch({
            type: actionTypes.REJECTED_CHART_DATA,
        })
    }
};
// GET DISTRIBUTION CONSOMMATION
export const getDistributionConsommation = ( value ) => async (dispatch, getState) => {
    try {
        dispatch({
            type: actionTypes.AWAITING_STACKED_CHART_DATA, 
        })

        const response = await axios.get(`${API_URL.GET_DISTRIBUTION_CONSOMMATION}?batiment=${value}`, credentialConfig(getState))

        dispatch({
            type: actionTypes.SUCCESS_STACKED_CHART_DATA,
            payload: {
                stackedChartData: response.data.stacked_chart_data,
            }
        })
    } catch (e) {
        dispatch({
            type: actionTypes.REJECTED_STACKED_CHART_DATA,
        })
    }
};

export const changeKwhGj = (e) => (dispatch) => { 
    dispatch({
        type: actionTypes.CHANGE_KWH_GJ,
        payload: {
            unit: e,
        }
    })
};

export function increment() {
    return {
        type: actionTypes.INCREMENT,
    }
};
export const changeBarmode = (e) => (dispatch) => {    
    dispatch({
        type: actionTypes.CHANGE_BARMODE,
        payload: {
            value: e.value,
            label: e.label,
        }
    })
};
export const changeBarnorm = (e) => (dispatch) => {    
    dispatch({
        type: actionTypes.CHANGE_BARNORM,
        payload: {
            value: e.value,
            label: e.label,
        }
    })
};
export const updateStartDate = (start) => (dispatch) => {    
    dispatch({
        type: actionTypes.UPDATE_START,
        payload: {
            startRange: start
        }
    })
};
export const updateEndDate = (end) => (dispatch) => {    
    dispatch({
        type: actionTypes.UPDATE_END,
        payload: {
            endRange: end
        }
    })
};
export function updateData() {
    return {
        type: actionTypes.UPDATE_DATA,
    }
};
export function resetData() {
    return {
        type: actionTypes.RESET_DATA,
    }
};
export const filterData = (date) => (dispatch) => {
    const startYear = date.getFullYear() 
    const startMonth = date.getMonth() + 1.
    const startDay = date.getDate()
    const dateFormatted = `${startYear}-${startMonth}-${startDay}`
    
    dispatch({
        type: actionTypes.FILTER_DATA,
        payload: {
            startRange: dateFormatted
        }
    })
};

// GET WEATHER NORM DATA
export const getWeatherNormData = (value) => (dispatch, getState) => {

    // Data loading
    dispatch({ type: actionTypes.GET_WEATHER_NORM_DATA_LOADING });

    axios
    .get(`${API_URL.GET_WEATHER_NORM_DATA}?batiment=${value}`, credentialConfig(getState))
    .then((res) => {
        dispatch({
            type: actionTypes.GET_WEATHER_NORM_DATA,
            payload: res.data
        });
    })
    .catch((error) => {
        // console.log(error)
        dispatch({
            type: actionTypes.GET_WEATHER_NORM_DATA_FAIL,
        })
    });
};