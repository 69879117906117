import * as actionTypes from '../actions/types';
import {numberWithSpaces} from '../../App/components/utils'

const initialState = {
    batiments: [{'code_batiment': ''}],
    selectedBatiment: '',
    infoBatiment: [{
        code_batiment:"-",
        nom:"-",
        adresse:"-",
        ville:"-",
        province: "-",
        etat:"-",
        superficie: '-',
        vocation: '-',
        mediane_vocation: 250.,
        // logoCompany: '',
    }],
    compteurs: [{
        "code_compteur": "-",
        "nom": "-",
        "fournisseur": "-",
        "date_debut": '-',
        "date_fin": '-',
        "etat": "-"
    }],
    chartFilterData: {
        startRange: '',
        endRange: '',
        minRange: '',
        maxRange: '',
        revision: 0,
        datarevision: 0,
    },
    data12mois: {
        intensite_energetique: {
            kwh: 0.,
            gj: 0.
        },
        intensite_monetaire: 0.,
        sources_energie: {
            pct: {
                'energie_propre': 50,
                'energie_fossile': 50
            },
            valeur: {
                'energie_propre': 0,
                'energie_fossile': 0
            },
            intensite: {
                'energie_propre': 0,
                'energie_fossile': 0
            },
        },
        chart: {
            // Période 12 mois (par mois)
            labels: [],
            pct_change: [],
            bar_colors: [],
            cout_total_12mois_pre: [],
            cout_total_12mois: [],
            cout_values: [],
            consommation_values: [],
        },
    },
    statsConsommation: {
        consommation_totale_12mois_kwh: "-",
        consommation_totale_12mois_gj: "-",
        cout_total_12mois: "-",
        emissions_GES_total_12mois: "-",
        intensite_energetique_12mois_kwh: "-",
        intensite_energetique_12mois_gj: "-",
        intensite_monetaire_12mois: "-"
    },
    statsPeriode: {
        'pie_charts':{
            'cout': [250, 250, 250],
            'emissions_GES': [250, 250, 250],
            'consommation': [250, 250, 250],
            'sources_energie': ['Électricité', 'Gaz', 'Mazout #2']
        },
        'table': [
            {
                "source_energie": "-",
                "cout_normalise": 0.,
                "emissions_GES": 0.,
                "consommation": 0.000000001,
                "facteur_conversion" : 1,
                'unite':  'kWh-éq.',
                "consommation_unite_native": 0.,
                "consommation_str": "-",
            },
        ],
        'isLoading': false
    },
    chartData: {
        'bilan': {
            'portrait':{
                'intensite_energetique': {
                    'kwh': 0.0,
                    'gj': 0.0,
                },
                'intensite_monetaire': 0.0,
                'sources_energie': {
                    'pct': {
                        'energie_propre': 50,
                        'energie_fossile': 50,
                    },
                    'valeur': {
                        'energie_propre': 0.0,
                        'energie_fossile': 0.0,
                    },
                    'intensite': {
                        'energie_propre': 0.0,
                        'energie_fossile': 0.0,
                    },
                },
            },
            'chart': {
                'labels': {
                    'complet':[],
                    'incomplet':[]
                },
            },
            'cout': {
                'actuel': 0.,
                'moyenne': 0.,
                'ecart': 0.,
                'values': {
                    'complet':[],
                    'incomplet':[]
                },
                'ecart_pct': 0.,
            },
            'consommation': {
                'actuel': 0.,
                'moyenne': 0.,
                'ecart': 0.,
                'values': {
                    'complet':[],
                    'incomplet':[]
                },
                'ecart_pct': 0.,
            },
            'emissions_GES':{
                'actuel': 0.,
                'moyenne': 0.,
                'ecart': 0.,
                'values': {
                    'complet':[],
                    'incomplet':[]
                },
                'ecart_pct': 0.,
            }
        },
        'detaillee':{
            'labels': [],
            'range': ['2000-01-01', '2000-12-31'],
            'range_mois': ['2000-01', '2000-12'],
            'consommation': {
                'kwh': [],
                'gj': []
            },
            'puissance': [],
            'cout': [],
            'emissions': [],
            'intensite_energetique': {
                'kwh': [],
                'gj': []
            },
            'intensite_monetaire': [],
            'emissions_flottante': []
        },
        'flagDonneesCompletes': true,
        'apiData': {'':''},
        'isLoading': false
    },
    weatherNormData: {
        'data': {
            'Non-disponible':
                {
                    'date':['2000-01-01'],
                    'consommation':[0.0],
                    'mean_ci_upper':[0.0],
                    'mean_ci_lower':[0.0],
                }
            },
        'availableData': false,
        'isLoading': false,
    },
    parameters: {
        unit: 'kWh-éq.',
        startRange: '2000-01-01',
        endRange: '2000-12-31',
    },
    logoCompany: '',
    apiErrors: {
        getChartData: false,
        // getBatiments: false,
        getInfoBatiment: false,
        // getStatsPeriode: false,
        getData12mois: false,
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.INITIAL_STATE:
            return {
                ...initialState
            }; 
        case actionTypes.DATA_LOADING:
            return {
                ...state,
                chartData: initialState.chartData,
                data12mois: initialState.data12mois,
                statsPeriode: initialState.statsPeriode,
            }; 
        case actionTypes.GET_WEATHER_NORM_DATA_LOADING:
            return {
                ...state,
                weatherNormData: {
                    ...state.weatherNormData,
                    isLoading: true
                },
            };
        case actionTypes.GET_WEATHER_NORM_DATA:
            return {
                ...state,
                weatherNormData: {
                    ...state.weatherNormData,
                    data: action.payload.weather_norm_data,
                    availableData: action.payload.available_data,
                    isLoading: false
                },
            };
        case actionTypes.GET_WEATHER_NORM_DATA_FAIL:
            return {
                ...state,
                weatherNormData: {
                    ...initialState.weatherNormData
                },
            };
        case actionTypes.GET_CHART_DATA_LOADING:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    isLoading: true
                },
            };
        case actionTypes.GET_CHART_DATA:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    bilan: action.payload.bilan,
                    detaillee: action.payload.detaillee,
                    flagDonneesCompletes: action.payload.flag_donnees_completes,
                    apiData: action.payload.api_data,
                    isLoading: false
                },
                parameters: {
                    ...state.parameters,
                    startRange: action.payload.detaillee.range_mois[0],
                    endRange: action.payload.detaillee.range_mois[1],
                },
                apiErrors: {
                    ...state.apiErrors,
                    getChartData: false
                }
            };
        case actionTypes.GET_CHART_DATA_FAIL:
            return {
                ...state,
                chartData: initialState.chartData,
                apiErrors: {
                    ...state.apiErrors,
                    getChartData: true
                }
            };
        case actionTypes.GET_BATIMENTS:
            return {
                ...state,
                batiments: action.payload,
                selectedBatiment: action.payload[0].id,
                apiErrors: {
                    ...state.apiErrors,
                    // getBatiments: false
                }
            };
        case actionTypes.GET_BATIMENTS_FAIL:
            return {
                ...state,
                batiments: initialState.batiments,
                selectedBatiment: initialState.selectedBatiment,
                apiErrors: {
                    ...state.apiErrors,
                    // getBatiments: true
                }
            };
        case actionTypes.GET_SELECTED_BATIMENT:
            return {
                ...state,
                selectedBatiment: action.payload.value,
                dataBatiment: action.payload.response,
            };
        case actionTypes.GET_STATS_CONSOMMATION:
            return {
                ...state,
                statsConsommation: action.payload
            }; 
        case actionTypes.GET_MIX_ENERGIE:
            return {
                ...state,
                mixEnergieBatiment: action.payload
            };        
        case actionTypes.GET_INFO_BATIMENT:
            return {
                ...state,
                infoBatiment: action.payload.info_batiment,
                compteurs: action.payload.compteurs,
                apiErrors: {
                    ...state.apiErrors,
                    getInfoBatiment: false
                }
            };
        case actionTypes.GET_INFO_BATIMENT_FAIL:
            return {
                ...state,
                infoBatiment: initialState.infoBatiment,
                apiErrors: {
                    ...state.apiErrors,
                    getInfoBatiment: true
                }
            };
        case actionTypes.GET_STATS_PERIODE:
            return {
                ...state,
                statsPeriode: {
                    ...state.statsPeriode,
                    pie_charts: action.payload.pie_charts,
                    table: action.payload.table,
                    isLoading: false
                },
                apiErrors: {
                    ...state.apiErrors,
                    // getStatsPeriode: false
                }
            };
        case actionTypes.GET_STATS_PERIODE_LOADING:
            return {
                ...state,
                statsPeriode: {
                    ...state.statsPeriode,
                    isLoading: true
                },
            };
        case actionTypes.GET_STATS_PERIODE_FAIL:
            return {
                ...state,
                statsPeriode: initialState.statsPeriode,
                apiErrors: {
                    ...state.apiErrors,
                    // getStatsPeriode: true
                }
            };
        case actionTypes.GET_DATA_12MOIS:
            return {
                ...state,
                data12mois: {
                    ...state.data12mois,
                    intensite_energetique: {
                        kwh: action.payload.intensite_energetique.kwh,
                        gj: action.payload.intensite_energetique.gj
                    },
                    intensite_monetaire: action.payload.intensite_monetaire,
                    sources_energie: {
                        ...state.data12mois.sources_energie,
                        pct: {
                            ...state.data12mois.sources_energie.pct,
                            energie_propre: action.payload.sources_energie.pct.energie_propre,
                            energie_fossile: action.payload.sources_energie.pct.energie_fossile    
                        },
                        valeur: {
                            ...state.data12mois.sources_energie.valeur,
                            energie_propre: action.payload.sources_energie.valeur.energie_propre,
                            energie_fossile: action.payload.sources_energie.valeur.energie_fossile    
                        },
                        intensite: {
                            ...state.data12mois.sources_energie.intensite,
                            energie_propre: action.payload.sources_energie.intensite.energie_propre,
                            energie_fossile: action.payload.sources_energie.intensite.energie_fossile    
                        },
                    },
                    chart:{
                        ...state.data12mois.chart,
                        // Période 12 mois (par mois)
                        labels: action.payload.chart_data.labels,
                        cout_values: action.payload.chart_data.cout_values,
                        consommation_values: action.payload.chart_data.consommation_values,
                    },
                },
                apiErrors: {
                    ...state.apiErrors,
                    getData12mois: false
                }
            };
        case actionTypes.GET_DATA_12MOIS_FAIL:
            return {
                ...state,
                data12mois: initialState.data12mois,
                apiErrors: {
                    ...state.apiErrors,
                    getData12mois: true
                }
            };
        case actionTypes.SUCCESS_CHART_DATA:
            return {
                ...state,
                infoBatiment: action.payload.infoBatiment,
            };
        case actionTypes.SUCCESS_STACKED_CHART_DATA:
            return {
                ...state,
                stackedChartData: action.payload.stackedChartData,
            };
        case actionTypes.INCREMENT:
            return {
                ...state,
                chartFilterData: {
                    ...state.chartFilterData,
                    revision: state.chartFilterData.revision + 1.,
                    datarevision: state.chartFilterData.revision + 1.,
                },
            };
        case actionTypes.CHANGE_KWH_GJ:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    unit: action.payload.unit,
                },
            };
        case actionTypes.GET_LOGO_COMPANY:
            return {
                ...state,
                logoCompany: action.payload 
            };
        case actionTypes.CHANGE_BARMODE:
            return {
                ...state,
                chartFilterData: {
                    ...state.chartFilterData,
                    revision: state.chartFilterData.revision + 1.,
                    datarevision: state.chartFilterData.revision + 1.,
                },
                chartDataNorm: {
                    ...state.chartDataNorm,
                    barmode:{
                        value: action.payload.value,
                        label: action.payload.label,    
                    }
                },
            };
        case actionTypes.FILTER_DATA:
            return {
                ...state,
                chartFilterData: {
                    ...state.chartFilterData,
                    revision: state.chartFilterData.revision + 1.,
                    datarevision: state.chartFilterData.revision + 1.,
                    startRange: action.payload.startRange,
                    //endRange: action.payload.chartDataBrute,
                },
            };
        case actionTypes.UPDATE_START:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    startRange: action.payload.startRange,
                },
            };
        case actionTypes.UPDATE_END:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    endRange: action.payload.endRange,
                },
            };
        case actionTypes.UPDATE_DATA:
            return {
                ...state,
                chartFilterData: {
                    ...state.chartFilterData,
                    revision: state.chartFilterData.revision + 1.,
                    datarevision: state.chartFilterData.datarevision + 1.,
                },
            };
        case actionTypes.RESET_DATA:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    startRange: state.chartData.detaillee.range_mois[0],
                    endRange: state.chartData.detaillee.range_mois[1], 
                },
            };
        default:
            return state;
    }
};