import axios from 'axios';

import API_URL from '../api_constants'
import * as actionTypes from '../actions/types';
import { returnErrors } from './messages';

// import Cookies from 'js-cookie';

// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"; 
// axios.defaults.xsrfCookieName = "csrftoken";

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: actionTypes.USER_LOADING });

  axios
    .get(API_URL.AUTH_USER, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: actionTypes.USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.AUTH_ERROR,
      });
    });
};

// LOGIN USER
export const login = (email, password) => (dispatch) => {
  // Request Body
  const body = JSON.stringify({ email, password });

  // Headers
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  
  // User Loading
  dispatch({ type: actionTypes.USER_LOADING });

  axios
    .post(API_URL.AUTH_LOGIN, body, config)
    .then((res) => {
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        payload: res.data,
        user: res.data.user,
        token: res.data.token,
      });
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch({
          type: actionTypes.LOGIN_SHOW_OTP,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: actionTypes.LOGIN_FAIL,
        });
      }
      return err;
    });
};

// LOGIN OTP USER
export const loginOTP = (email, password, totp) => (dispatch) => {
  // Request Body
  const body = JSON.stringify({ email, password, totp });

  // Headers
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // User Loading
  dispatch({ type: actionTypes.USER_LOADING });
  
  // POST request
  axios
    .post(API_URL.AUTH_LOGIN_OTP, body, config)
    .then((res) => {
      dispatch({
        type: actionTypes.LOGIN_OTP_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.LOGIN_OTP_FAIL,
      });
    });
};

// RETRY LOGIN
export const retryLogin = () => (dispatch) => {
  dispatch({
    type: actionTypes.RETRY_LOGIN,
  });
};

// REGISTER USER
export const register = ({ username, password, email }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
    
  // Request Body
  const body = JSON.stringify({ username, email, password });

  axios
    .post(API_URL.AUTH_REGISTER, body, config)
    .then((res) => {
      dispatch({
        type: actionTypes.REGISTER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: actionTypes.REGISTER_FAIL,
      });
    });
};
  
// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  axios
    .post(API_URL.AUTH_LOGOUT, null,tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: actionTypes.INITIAL_STATE,
      });
      dispatch({
        type: actionTypes.LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
  
// HANDLE MSG ERROR
export const onCloseError = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLOSE_ERROR,
  });
};
// HANDLE MSG SUCCESS
export const onCloseSuccess = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLOSE_SUCCESS,
  });
};
// HANDLE MODAL CHANGE PASSWORD CLOSE
export const onCloseModalChangePassword = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLOSE_MODAL_CHANGE_PWD,
  });
};
// CHANGE PASSWORD
export const changePassword = (password, password2, old_password, id) => (dispatch, getState) => {
  // Request Body
  const body = JSON.stringify({ password, password2, old_password });
  var bodyFormData = new FormData();
  bodyFormData.append('password', password);
  bodyFormData.append('password2', password2);
  bodyFormData.append('old_password', old_password);
  // bodyFormData.append('csrfmiddlewaretoken', Cookies.get('csrftoken'));
  
  // Headers
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      // 'X-CSRFToken': 'KGOUCiAmi22OCh332TnAvk0l6QRJIIsoRFe844MogJbEEf8ODXiQ869vEyt9nBvn',
    },
  };

  axios
    .put(`${API_URL.AUTH_CHANGE_PASSWORD}/${id}`, bodyFormData, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      // Error 😨
      if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          dispatch({
            type: actionTypes.CHANGE_PASSWORD_FAIL,
            payload: error.response.data,
          });
          // console.log('Data',error.response.data);
          // console.log('Status',error.response.status);
          // console.log('Headers',error.response.headers);
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
      } else {
          // Something happened in setting up the request and triggered an Error
      }
    });
};

// FORGOT PASSWORD
export const checkUsername = (email) => (dispatch) => {
  // Request Body
  var bodyFormData = new FormData();
  bodyFormData.append('email', email);
  
  // Headers
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  axios
    .post(`${API_URL.AUTH_CHECK_USERNAME}`, bodyFormData)
    .then((res) => {
      dispatch({
        type: actionTypes.CHECK_USERNAME_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      // Error 😨
      if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          dispatch({
            type: actionTypes.CHECK_USERNAME_FAIL,
            payload: error.response.data,
          });
          // console.log('Data',error.response.data);
          // console.log('Status',error.response.status);
          // console.log('Headers',error.response.headers);
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
      } else {
          // Something happened in setting up the request and triggered an Error
      }
    });
};

export const changeForgotPassword = (email,password,password2,totp) => (dispatch) => {
  // Request Body
  var bodyFormData = new FormData();
  bodyFormData.append('email', email);
  bodyFormData.append('password', password);
  bodyFormData.append('password2', password2);
  bodyFormData.append('totp', totp);
  // Headers
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  axios
    .put(`${API_URL.AUTH_CHANGE_FORGOT_PASSWORD}`, bodyFormData)
    .then((res) => {
      dispatch({
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      // Error 😨
      if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          dispatch({
            type: actionTypes.CHANGE_PASSWORD_FAIL,
            payload: error.response.data,
          });
          // console.log('Data',error.response.data);
          // console.log('Status',error.response.status);
          // console.log('Headers',error.response.headers);
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
      } else {
          // Something happened in setting up the request and triggered an Error
      }
    });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  return config;
};
// Setup config with token - helper function
export const credentialConfig = (getState) => {
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers['Authorization'] = `Token ${token}`;
  // }

  return config;
};