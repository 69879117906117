export default {
    items: [
        {
            id: 'navigation',
            title: 'Bilan énergétique',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'tableau',
                    title: 'Bilan de la consommation',
                    titleBreadcrumb: 'Bilan de la consommation énergétique',
                    type: 'item',
                    url: '/bilan',
                    icon: 'feather icon-activity',
                    // badge: {
                    //     title: 'Nouveau',
                    //     type: 'label-success',
                    //     color: "#c3d332"
                    // },
                },
                {
                    id: 'consommation-detaillee',
                    title: 'Consommation détaillée',
                    titleBreadcrumb: 'Consommation détaillée',
                    type: 'item',
                    url: '/consommation-detaillee',
                    icon: 'feather icon-bar-chart-2',
                    // classes: 'disabled',
                    // badge: {
                    //     title: 'Nouveau',
                    //     type: 'label-success',
                    //     color: "#c3d332"
                    // },
                },
                {
                    id: 'parc-immobilier',
                    title: 'Parc immobilier',
                    titleBreadcrumb: 'Parc immobilier',
                    type: 'item',
                    url: '/parc-immobilier',
                    icon: 'feather icon-grid',
                    disabled: true,
                    classes: 'disabled',
                    // badge: {
                    //     title: 'En dév.',
                    //     type: 'label-warning',
                    //     color: "#faa938"
                    // },
                },
                // {
                //     id: 'analyse-energetique',
                //     title: 'Analyse énergétique',
                //     type: 'item',
                //     url: '#',
                //     icon: 'feather icon-grid',
                //     disabled: true,
                //     classes: 'disabled',
                //     badge: {
                //         title: 'En dév.',
                //         type: 'label-warning',
                //         color: "#faa938"
                //     },
                // },
                // {
                //     id: 'suivi-energetique',
                //     title: 'Suivi énergétique',
                //     type: 'item',
                //     url: '#',
                //     icon: 'feather icon-pie-chart',
                //     disabled: true,
                //     classes: 'disabled',
                //     // badge: {
                //     //     title: 'En dév.',
                //     //     type: 'label-warning',
                //     //     color: "#faa938"
                //     // },
                // },
            ]
        },
        // {
        //     id: 'projets',
        //     title: 'Projets',
        //     type: 'group',
        //     icon: 'icon-navigation',
        //     children: [
        //         {
        //             id: 'suivi-projets',
        //             title: 'Suivi des projets',
        //             type: 'item',
        //             url: '#',
        //             icon: 'feather icon-clipboard',
        //             disabled: true,
        //             classes: 'disabled',
        //             // badge: {
        //             //     title: 'À venir',
        //             //     type: 'label-info',
        //             //     color: "#0060ae"
        //             // },
        //         },
        //         {
        //             id: 'equipements-cvca',
        //             title: 'Équipements CVCA',
        //             type: 'item',
        //             url: '#',
        //             icon: 'feather feather-folder',
        //             disabled: true,
        //             classes: 'disabled',
        //             // badge: {
        //             //     title: 'À venir',
        //             //     type: 'label-info',
        //             //     color: "#0060ae"
        //             // },
        //         },
        //         {
        //             id: 'rapports',
        //             title: 'Rapports en ligne',
        //             type: 'item',
        //             url: '#',
        //             icon: 'feather icon-file-text',
        //             disabled: true,
        //             classes: 'disabled',
        //             // badge: {
        //             //     title: 'À venir',
        //             //     type: 'label-info',
        //             //     color: "#0060ae"
        //             // },
        //         },
        //         {
        //             id: 'reddition-comptes',
        //             title: 'Reddition de comptes',
        //             type: 'item',
        //             url: '#',
        //             icon: 'feather icon-target',
        //             disabled: true,
        //             classes: 'disabled',
        //             // badge: {
        //             //     title: 'À venir',
        //             //     type: 'label-info',
        //             //     color: "#0060ae"
        //             // },
        //         },
        //     ]
        // },
        {
            id: 'support',
            title: 'Support',
            type: 'group',
            icon: 'icon-ui',
            children: [
                {
                    id: 'services',
                    title: 'Services',
                    type: 'collapse',
                    icon: 'feather icon-star',
                    icon_external: true,
                    children: [
                        {
                            id: 'service-analyse',
                            title: 'Analyse énergétique',
                            type: 'item',
                            external: true,
                            url: 'https://www.energenia.ca/services/expertise/analyse-energetique/'
                        },
                        {
                            id: 'service-aph-select',
                            title: 'APH Select de la SCHL',
                            type: 'item',
                            external: true,
                            url: 'https://www.energenia.ca/aph-select/'
                        },
                        {
                            id: 'service-plans-contruction',
                            title: 'Plans et devis',
                            type: 'item',
                            external: true,
                            url: 'https://www.energenia.ca/services/plans-construction/'
                        },
                        {
                            id: 'service-modelisation',
                            title: 'Modélisation énergétique',
                            type: 'item',
                            external: true,
                            url: 'https://www.energenia.ca/modelisation-energetique/'
                        },
                        {
                            id: 'service-rcx',
                            title: 'Recommissioning',
                            type: 'item',
                            external: true,
                            url: 'http://www.energenia.ca/services/expertise/recommissioning-rcx/'
                        },
                        {
                            id: 'service-mes',
                            title: 'Surveillance et mise en service',
                            type: 'item',
                            external: true,
                            url: 'https://www.energenia.ca/services/plans-construction/'
                        },
                        {
                            id: 'service-exploitation',
                            title: "Soutien à l'exploitation",
                            type: 'item',
                            external: true,
                            url: 'https://www.energenia.ca/services/expertise/telegestion-et-suivi-energetique/'
                        },
                        {
                            id: 'service-telegestion-suivi',
                            title: 'Télégestion et suivi énergétique',
                            type: 'item',
                            external: true,
                            url: 'http://www.energenia.ca/services/expertise/telegestion-et-suivi-energetique/'
                        },
                    ]
                },
                // {
                //     id: 'appel-service',
                //     title: 'Appel de service',
                //     type: 'item',
                //     url: '#',
                //     icon: 'feather icon-phone-call',
                //     disabled: true,
                //     classes: 'disabled',
                //     // badge: {
                //     //     title: 'À venir',
                //     //     type: 'label-info',
                //     //     color: "#0060ae"
                //     // },
                // },
                // {
                //     id: 'clavardage',
                //     title: 'Clavardage',
                //     type: 'item',
                //     url: '#',
                //     icon: 'feather icon-users',
                //     disabled: true,
                //     classes: 'disabled',
                //     // badge: {
                //     //     title: 'À venir',
                //     //     type: 'label-info',
                //     //     color: "#0060ae"
                //     // },
                // },
                // {
                //     id: 'assistance',
                //     title: 'Assistance',
                //     type: 'item',
                //     url: '#',
                //     titleBreadcrumb: 'Assistance',
                //     icon: 'feather icon-message-circle',
                //     disabled: true,
                //     classes: 'disabled',
                //     // badge: {
                //     //     title: 'À venir',
                //     //     type: 'label-info',
                //     //     color: "#0060ae"
                //     // },
                // },
                {
                    id: 'contact',
                    title: 'Contactez-nous',
                    type: 'item',
                    external: true,
                    url: 'https://www.energenia.ca/contact/',
                    icon: 'feather icon-user',
                },
                // {
                //     id: 'deconnexion',
                //     title: 'Déconnexion',
                //     type: 'item',
                //     url: '#',
                //     icon: 'feather icon-log-out',
                //     logout: true,
                // },
            ]
        },

    ]
}