const backendURL = 'https://facto.energenia.ca:8000'

const API_URL = {
    // Auth API
    AUTH_USER: `${backendURL}/api/auth/user`,
    AUTH_LOGIN: `${backendURL}/api/auth/login`,
    AUTH_LOGIN_OTP: `${backendURL}/api/auth/login-otp`,
    AUTH_REGISTER: `${backendURL}/api/auth/register`,    
    AUTH_LOGOUT: `${backendURL}/api/auth/logout/`,
    AUTH_CHANGE_PASSWORD: `${backendURL}/api/auth/change_password`,
    AUTH_CHECK_USERNAME: `${backendURL}/api/auth/check_username`,
    AUTH_CHANGE_FORGOT_PASSWORD: `${backendURL}/api/auth/change_forgot_password`, 
    // Info_batiment API
    GET_BATIMENTS: `${backendURL}/ajax/batiments/`,
    GET_INFO_BATIMENT: `${backendURL}/ajax/info_batiment/`,
    GET_STATS_CONSOMMATION: `${backendURL}/ajax/stats_consommation/`,
    GET_MIX_ENERGIE: `${backendURL}/ajax/mix_energie/`,
    GET_DISTRIBUTION_CONSOMMATION: `${backendURL}/ajax/distribution_consommation/`,
    GET_DATA_12MOIS: `${backendURL}/ajax/data_12mois/`,
    GET_STATS_PERIODE: `${backendURL}/ajax/stats_periode/`,
    GET_CHART_DATA: `${backendURL}/ajax/chart_data/`,
    GET_LOGO_COMPANY: `${backendURL}/ajax/logo/`,
    GET_WEATHER_NORM_DATA: `${backendURL}/ajax/weather_norm_data/`,
};

export default API_URL;
