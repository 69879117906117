import * as actionTypes from '../actions/types';

import {updateObject} from './utils'

const initialState = {
    token: localStorage.getItem('token'),
    // token: null,
    isAuthenticated: null,
    isLoading: false,
    user: {
        email: '',
        first_name: '',
        last_name: '',
    },
    error: false,
    showOTPField: false,
    errorMessage: '',
    successMessage: '',
    showErrorMessage: false,
    showSuccessMessage: false,
  };
  
export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.INITIAL_STATE_AUTH:
            return {
                ...initialState
            }; 
        case actionTypes.USER_LOADING:
        return {
            ...state,
            isLoading: true,
        };
        case actionTypes.USER_LOADED:
        return {
            ...state,
            isAuthenticated: true,
            isLoading: false,
            user: action.payload,
        };
        case actionTypes.LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                // ...action.payload,
                isAuthenticated: true,
                isLoading: false,
                error: false,
                showOTPField: false,
                token: action.payload.token,
                user: action.payload.user
            };
        case actionTypes.LOGIN_OTP_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                // ...action.payload,
                isAuthenticated: true,
                isLoading: false,
                error: false,
                showOTPField: false,
                token: action.payload.token,
                user: action.payload.user,
            };
        case actionTypes.REGISTER_SUCCESS:
            localStorage.setItem('token', action.payload.token);
        return {
            ...state,
            ...action.payload,
            isAuthenticated: true,
            isLoading: false,
        };
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            showSuccessMessage: true,
            successMessage: action.payload.message
        };
        case actionTypes.AUTH_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
            };            
        case actionTypes.LOGIN_FAIL:
            return {
                ...state,
                error: true,
                isLoading: false,
            };
        case actionTypes.LOGIN_OTP_FAIL:
            return {
                ...state,
                error: true,
                isLoading: false,
            };
        case actionTypes.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                errorMessage: action.payload,
                showErrorMessage: true,
            };
        case actionTypes.CLOSE_ERROR:
            return {
                ...state,
                showErrorMessage: false,
            };
        case actionTypes.CLOSE_SUCCESS:
            return {
                ...state,
                showSuccessMessage: false,
            };
        case actionTypes.CLOSE_MODAL_CHANGE_PWD:
            return {
                ...state,
                showSuccessMessage: false,
                showErrorMessage: false,
            };
        case actionTypes.RETRY_LOGIN:
            return {
                ...state,
                ...action.payload,
                error: false,
            };
        case actionTypes.LOGIN_SHOW_OTP:
            return {
                ...state,
                showOTPField: action.payload.show_otp_field,
                error: false,
                isLoading: false
            };
        case actionTypes.LOGOUT_SUCCESS:
            localStorage.removeItem('token');
        case actionTypes.REGISTER_FAIL:
            localStorage.removeItem('token');
        return {
            ...state,
            token: null,
            user: null,
            isAuthenticated: false,
            isLoading: false,
        };
        default:
        return state;
    }
}